<template>
  <b-overlay
    :show="loading"
    variant="transparent"
    opacity="1"
    blur="5px"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card>
      <b-form
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col
            cols="6"
            md="6"
          >
            <b-form-group
              :label="$t('users.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="clientDataForm.name"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="6"
            md="6"
          >
            <b-form-group
              :label="$t('users.surname')"
              label-for="surname"
            >
              <b-form-input
                id="surname"
                v-model="clientDataForm.surname"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="$t('users.legal_id')"
              label-for="legal_id"
            >
              <b-form-input
                id="legal_id"
                v-model="clientDataForm.legal_id"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="$t('users.telephone')"
              label-for="telephone"
            >
              <b-form-input
                id="telephone"
                v-model="clientDataForm.telephone"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              :label="$t('users.email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="clientDataForm.email"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              :label="$t('users.address1')"
              label-for="address1"
            >
              <b-form-input
                id="address1"
                v-model="clientDataForm.address1"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group>
              <b-form-input
                id="address2"
                v-model="clientDataForm.address2"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              :label="$t('users.city')"
              label-for="city"
            >
              <b-form-input
                id="city"
                v-model="clientDataForm.city"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              :label="$t('users.postal_code')"
              label-for="postal_code"
            >
              <b-form-input
                id="postal_code"
                v-model="clientDataForm.postal_code"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('users.state')"
              label-for="state"
            >
              <b-form-input
                id="state"
                v-model="clientDataForm.state"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              :label="$t('users.comments')"
              label-for="comments"
            >
              <b-form-textarea
                id="comments"
                v-model="clientDataForm.comments"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1 btn-block"
              type="submit"
              :disabled="loading"
            >
              <b-spinner
                v-if="loading"
                small
                type="grow"
              />
              {{ this.$t('save_changes') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BCard, BButton, BOverlay, BSpinner,
} from 'bootstrap-vue'
import {
  ref, watch, onBeforeMount, computed,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import i18n from "@/libs/i18n"
import useClientView from './useClientView'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    BButton,
    BOverlay,
    BSpinner,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
  },
  setup() {
    const loading = ref(false)
    const toast = useToast()

    const { clientData, updateClient } = useClientView()
    const clientDataForm = computed(() => clientData.value)

    const {
      getValidationState,
    } = formValidation(clientData)

    const onSubmit = async () => {
      loading.value = true
      await updateClient(clientDataForm.value)
          .then(() => {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('changes_saved'),
                variant: 'success',
              },
            })
          })
          .catch(error => {
          })
      loading.value = false
    }

    return {
      clientData,
      clientDataForm,
      onSubmit,
      loading,
    }
  },
}
</script>

<style>

</style>
