<template>
  <div>
    <b-row>
      <b-col
        md="12"
        lg="12"
      >
        <b-card v-if="clientProjectData === undefined">
          <b-card-text>
            {{ $t("clients.no-projects") }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row
      v-for="project in clientProjectData"
      :key="project.id"
    >
      <b-col
        md="12"
        lg="12"
      >
        <b-card>
          <b-card-text>
            <b-link
                :to="{ name: 'projects-view', params: { id: project.id } }"
                class="font-weight-bold d-block"
            >
              {{ project.name }}
            </b-link>

            {{ project.comments }}
            <p>
              {{ $t("projects.responsible") }}: {{ project.responsible }}
            </p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BCard, BCardText, BLink,
} from 'bootstrap-vue'
import {
  ref,
} from '@vue/composition-api'
import store from "@/store"
import router from "@/router"

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
  },
  props: {
  },
  setup() {
    const clientProjectData = ref(null)
    store.dispatch('model/client/fetchClientProjects', { id: router.currentRoute.params.id })
        .then(response => {
          clientProjectData.value = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            clientProjectData.value = undefined
          }
        })

    return {
      clientProjectData,
    }
  },
}
</script>

<style>

</style>
