export default {
  namespaced: true,
  state: {
    data: null,
  },
  getters: {
    getData(state) {
      return state.data
    },
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    updateData(state, data) {
      Object.entries(data).forEach(([key, val]) => { state.data[key] = val })
    },
  },
  actions: {},
}
