import {
  ref, reactive, computed,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useClientView() {
  const clientData = computed(() => store.state.client.data)
  const clientJobs = reactive({
    projects: null,
    maintenances: null,
  })

  const fetchClient = async params => {
    await store.dispatch('model/client/fetchClient', params)
      .then(response => {
        store.commit('client/setData', response.data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          store.commit('client/setData', null)
        }
      })
  }

  const updateClient = async params => {
    await store.dispatch('model/client/updateClient', params)
    .then(response => {
      store.commit('client/updateData', response.data)
    })
    .catch(error => {
      if (error.response.status === 404) {
        store.commit('client/updateData', null)
      }
    })
  }

  const fetchClientProjects = async () => {
    await store.dispatch('model/client/fetchClientProjects', { id: router.currentRoute.params.id })
      .then(response => {
        clientJobs.projects = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          clientJobs.projects = undefined
        }
      })
  }

  const fetchClientMaintenances = async () => {
    await store.dispatch('model/client/fetchClientMaintenances', { id: router.currentRoute.params.id })
      .then(response => {
        clientJobs.maintenances = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          clientJobs.maintenances = undefined
        }
      })
  }

  return {
    fetchClient,
    fetchClientProjects,
    fetchClientMaintenances,
    updateClient,
    clientData,
    clientJobs,
  }
}
