<template>
  <div>
    <project-list-add-new
      :is-add-new-project-sidebar-active.sync="isAddNewProjectSidebarActive"
      :client_id="clientData.id"
    />

    <b-row>
      <b-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
      >
        <client-view-client-info-card />
      </b-col>

      <b-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
      >
        <b-row>
          <b-col>
            <b-button
              v-if="$can('create', 'projects')"
              variant="primary"
              @click="isAddNewProjectSidebarActive = true"
              class="mb-1"
            >
              <span class="text-nowrap">{{ $t("projects.add_project") }}</span>
            </b-button>
          </b-col>
        </b-row>

        <client-view-projects-card />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'

import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import {
  computed, onBeforeMount, onMounted, onUnmounted, ref,
} from "@vue/composition-api"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from "vue-toastification/composition"
import i18n from '@/libs/i18n'
import ClientViewClientInfoCard from './ClientViewClientInfoCard.vue'
import ClientViewProjectsCard from './ClientViewProjectsCard.vue'
import clientStoreModule from '../clientStoreModule'
import useClientView from './useClientView'
import ProjectListAddNew from '../../project/projects-list/ProjectListAddNew.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,

    ClientViewClientInfoCard,
    ClientViewProjectsCard,
    ProjectListAddNew,
  },
  setup() {
    const isAddNewProjectSidebarActive = ref(false)
    const CLIENT_APP_STORE_MODULE_NAME = 'client'

    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()
    const loading = ref(false)

    const {
      clientData, fetchClient,
    } = useClientView()

    onBeforeMount(async () => {
      loading.value = true
      await fetchClient({ id: router.currentRoute.params.id })
      if (!clientData.value) {
        // return back a listado, con ejemplo de toast y i18n en composition
        router.push({
          name: 'clients-list',
        }).then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${i18n.t('errors.Not found')} ${i18n.t('clients.title')}`,
              icon: 'XOctagonIcon',
              variant: 'danger',
              text: `${i18n.t('errors.Not found id')}`,
            },
          })
        })
      }
      loading.value = false
    })

    return {
      loading,
      clientData,
      isAddNewProjectSidebarActive,
    }
  },
}
</script>

<style>

</style>
